import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Avatar,
  Box,
  Container,
  Grid,
  IconButton,
  List,
  Stack,
  SwipeableDrawer,
  Toolbar,
  Typography,
} from '@mui/material';
import BaseAppBar from '@mui/material/AppBar';
import MenuIcon from '@mui/icons-material/Menu';
import Logo from '../assets/images/logo192.png';
import { PAGES } from '../util/constants';
import { getTextColor } from '../util/util';
import NavItem from './NavItem';
import NavItemMobile from './NavItemMobile';
import NavMenu from './NavMenu';
import NavMenuMobile from './NavMenuMobile';
import UkraineBadge from './UkraineBadge';

const Appbar = (props) => {
  const bgColorDesktop = 'rgb(173,216,230,0.8)';
  const bgColorMobile = 'rgb(173,216,230,1)';
  const location = useLocation().pathname.replace('/', '');

  const [open, setOpen] = useState(false);

  const handleCloseDrawer = () => {
    setOpen(false);
  };

  return (
    <BaseAppBar
      position="absolute"
      sx={{
        backgroundColor: {
          xs: 'rgb(173,216,230,1)',
          md: 'rgb(173,216,230,0.8)',
        },
        mt: {
          md: 4,
        },
        mr: 1,
      }}
    >
      <Container maxWidth="xl">
        {/* Mobile Appbar */}
        <Toolbar
          disableGutters
          sx={{
            display: { xs: 'flex', md: 'none' },
          }}
        >
          <Grid container>
            {/* Left content */}
            <Grid
              item
              xs={4}
              sx={{
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'center',
              }}
            >
              <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={() => setOpen(true)}
                >
                  <MenuIcon />
                </IconButton>
                <SwipeableDrawer
                  open={open}
                  onClose={handleCloseDrawer}
                  onOpen={() => setOpen(true)}
                  PaperProps={{
                    sx: {
                      backgroundColor: bgColorMobile,
                      width: '40%',
                    },
                  }}
                >
                  <List>
                    {PAGES.map((page) =>
                      page.path ? (
                        <NavItemMobile
                          key={page.name}
                          name={page.name}
                          path={page.path}
                          bgColor={bgColorMobile}
                          onClick={handleCloseDrawer}
                        />
                      ) : (
                        <NavMenuMobile
                          key={page.name}
                          {...page}
                          bgColor={bgColorMobile}
                          closeDrawer={handleCloseDrawer}
                        />
                      )
                    )}
                  </List>
                </SwipeableDrawer>
              </Box>
            </Grid>

            {/* Middle content */}
            <Grid
              item
              xs={4}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography
                color={getTextColor(bgColorMobile)}
                sx={{ textTransform: 'capitalize' }}
              >
                {location}
              </Typography>
            </Grid>

            {/* Right content */}
            <Grid
              item
              xs={4}
              sx={{
                display: 'flex',
                justifyContent: 'end',
                alignItems: 'center',
                pr: 3,
              }}
            >
              <Avatar src={Logo} />
            </Grid>
          </Grid>
        </Toolbar>

        {/* Desktop Appbar */}
        <Toolbar
          disableGutters
          sx={{
            py: '0px !important',
            display: { xs: 'none', md: 'flex' },
          }}
        >
          <Avatar src={Logo} sx={{ opacity: 1 }} />
          <Box
            component="nav"
            sx={{
              ml: 2,
              display: 'flex',
              flexGrow: 1,
            }}
          >
            <Stack direction="row">
              {PAGES.map((page) =>
                page.path ? (
                  <NavItem key={page.name} {...page} bgColor={bgColorDesktop} />
                ) : (
                  <NavMenu key={page.name} {...page} bgColor={bgColorDesktop} />
                )
              )}
            </Stack>
          </Box>
        </Toolbar>
        <Box
          sx={{
            // display: { xs: 'none', lg: 'auto', xl: 'auto' },
            position: 'absolute',
            top: -5,
            right: -5,
          }}
        >
          <UkraineBadge />
        </Box>
      </Container>
    </BaseAppBar>
  );
};

export default Appbar;
