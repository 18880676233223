import React from 'react';
import { Box, Link, Tooltip, Typography } from '@mui/material';
import ukraine from '../assets/images/ukraine.png';

const UkraineBadge = (props) => {
  return (
    <Tooltip title={<Typography sx={{}}>{'#westandwithukraine'}</Typography>}>
      <Link href="https://war.ukraine.ua/support-ukraine/" target="_blank">
        <img src={ukraine} alt="" />
      </Link>
    </Tooltip>
  );
};

export default UkraineBadge;
