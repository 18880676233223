import { Paper, Box, Grid, Typography, Stack } from '@mui/material';
import React from 'react';
import treehouse from '../assets/images/impressum.jpg';
import aboutus from '../assets/images/aboutus.jpg';
import logo from '../assets/images/logo192.png';

const About = (props) => {
  return (
    <Box sx={{ display: 'flex', flexGrow: 1 }}>
      <Grid container>
        <Grid item xs={0} md={6}>
          <Grid container sx={{ height: '100%' }}>
            <Grid item xs={12} />
            <Grid
              item
              xs={12}
              sx={{
                p: 2,
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
              }}
            >
              <Box>
                <img
                  style={{
                    width: '100%',
                    maxWidth: '600px',
                    maxHeight: '45vh',
                    borderRadius: '5px',
                    boxShadow: '10px 15px 15px',
                  }}
                  src={aboutus}
                  alt=""
                />
              </Box>
            </Grid>
            <Grid item xs={12} />
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            p: 2,
          }}
        >
          <Paper
            sx={{
              height: { xs: '45vh', md: '85vh' },
              p: 2,
              opacity: 0.9,
              overflowY: 'scroll',
              overflowX: 'hidden',
              justifyContent: 'center',
            }}
          >
            <Typography sx={{ fontSize: '24px', whiteSpace: 'pre-line' }}>
              {`
              Supporting germany’s southern developer scene, the Black Forest area is where our ideas grow to life. Its good infrastructure & sunny weather is a top environment. 
              
              The soil which we grow our games on is 100% self-funded, so we always can ensure our visions to be what we want them to be. 

              We draw our inspiration from all kinds of sources and experiences. Colorful, funny, engaging and smart blends for tasty game creations.

              Meet the core of TideOver Studios, and get to know the friendly people behind our creations.`}
            </Typography>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                pt: 15,
              }}
            >
              <img src={logo} alt="logo" />
            </Box>
          </Paper>
        </Grid>
      </Grid>
      <img
        style={{
          zIndex: '-1',
          position: 'absolute',
          objectFit: 'cover',
          width: '100vw',
          height: '100vh',
          top: 0,
          left: 0,
          padding: 'none',
        }}
        src={treehouse}
        alt=""
      />
    </Box>
  );
};

export default About;
