import { Collapse, List, ListItemButton, ListItemText } from '@mui/material'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import React from 'react'
import NavItemMobile from './NavItemMobile'

const NavMenuMobile = (props) => {
  const [open, setOpen] = React.useState(true)

  const handleClick = () => {
    setOpen(!open)
  }

  return (
    <>
      <ListItemButton
        onClick={handleClick}
        sx={{
          backgroundColor: props.bgColor,
        }}
      >
        <ListItemText>{props.name}</ListItemText>
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto">
        <List disablePadding>
          {props.items.map((item) => (
            <NavItemMobile
              {...item}
              sx={{ pl: 4 }}
              onClick={props.closeDrawer}
            />
          ))}
        </List>
      </Collapse>
    </>
  )
}

export default NavMenuMobile
