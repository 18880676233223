import ReactGA from 'react-ga';
import { Navigate, Route, Routes } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import './App.css';

import Layout from './components/Layout';
import { Homepage, Roadmap, Impressum, News, About } from './pages';

function App() {
  library.add(fab, fas);

  ReactGA.initialize('UA-204202959-2');
  ReactGA.pageview(window.location.pathname + window.location.search);

  return (
    <div className="App">
      <Layout>
        <Routes>
          <Route path="/" element={<Navigate to="/home" />} />
          <Route path="/home" element={<Homepage />} />
          <Route path="/roadmap" element={<Roadmap />} />
          <Route path="/impressum" element={<Impressum />} />
          {/* <Route path="/news" element={<News />} /> */}
          <Route path="/about" element={<About />} />
          <Route path="*" element={<Navigate to="/home" />} />
        </Routes>
      </Layout>
    </div>
  );
}

export default App;
