import { Stack } from '@mui/material'
import PropTypes from 'prop-types'
import BaseTypography from '@mui/material/Typography'

const Typography = (props) => {
  return (
    <BaseTypography
      {...props}
      sx={{
        fontFamily: 'Arial',
        whiteSpace: 'pre-line !important',
        ...props.sx,
      }}
    >
      {props.children}
    </BaseTypography>
  )
}

const ImpressumBlock = (props) => {
  return (
    <Stack sx={{ pt: 2 }}>
      {props.title && (
        <Typography sx={{ fontWeight: 'bold' }}>{props.title}</Typography>
      )}
      {props.text && <Typography>{props.text}</Typography>}
    </Stack>
  )
}

ImpressumBlock.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
}

export default ImpressumBlock
