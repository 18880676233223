import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';

import BaseTypography from '@mui/material/Typography';

import { AccessTime, CheckCircle } from '@mui/icons-material';

const Typography = (props) => {
  return (
    <BaseTypography
      {...props}
      sx={{ color: 'black', fontFamily: 'Gagalin, sans-serif', ...props.sx }}
    >
      {props.children}
    </BaseTypography>
  );
};

const RoadmapBox = ({ goals, title, titleColor, md }) => {
  const color = titleColor || '#acd7e5';

  return (
    <Grid item xs={12} md={md} sx={{ display: 'grid' }} className="tos-anim3">
      <Card
        sx={{
          pb: 2,
          backgroundColor: 'rgba(255, 255, 255, 0.4)',
        }}
      >
        <Box sx={{ backgroundColor: color, p: 1 }}>
          <Typography variant="h6">{title}</Typography>
        </Box>
        <List>
          {goals.map((ele, i) => {
            return (
              <ListItem
                key={i}
                sx={{ display: 'flex', justifyContent: 'space-between' }}
              >
                {ele.icon && <ListItemIcon>{ele.icon}</ListItemIcon>}
                <ListItemText>
                  <Typography>{ele.text}</Typography>
                </ListItemText>
                {ele.status ? (
                  <CheckCircle sx={{ color: 'green' }} />
                ) : (
                  <AccessTime />
                )}
              </ListItem>
            );
          })}
        </List>
      </Card>
    </Grid>
  );
};

RoadmapBox.propTypes = {
  goals: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  titleColor: PropTypes.string,
};

export default RoadmapBox;
