import { Box, Stack, Typography } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import IconButton from '../components/IconButton'
import { LINKS } from '../util/constants'
import SocialLinks from '../components/SocialLinks'

import background from '../assets/HomePage_TideOverMovie.mp4'
import background_img from '../assets/images/video_placeholder.jpg'
import FloatingButton from '../components/FloatingButton'

const Homepage = (props) => {
  return (
    <Box sx={{ display: 'flex', flexGrow: 1 }}>
      <Stack
        sx={{
          display: 'flex',
          flexGrow: 1,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box className="tos-anim1">
          <Typography variant="h4" color="white">
            - A BLACK FOREST STARTUP -
          </Typography>
          <Stack
            direction="row"
            sx={{ display: 'flex', justifyContent: 'center' }}
          >
            <Typography variant="h1" color="white">
              TIDEOVER
            </Typography>
            <Typography variant="h1" color="lightblue">
              STUDIOS
            </Typography>
          </Stack>
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            spacing={{ xs: 3, md: 5 }}
            sx={{
              display: { xs: 'grid', md: 'flex' },
              justifyContent: 'center',
              alignItems: 'center',
              pt: 2,
            }}
          >
            <IconButton
              icon={<FontAwesomeIcon icon="fab fa-steam" />}
              href={LINKS.steam}
            >
              SourceWorlds
            </IconButton>
            <IconButton
              icon={<FontAwesomeIcon icon="fab fa-youtube" />}
              href={LINKS.trailer}
            >
              Trailer
            </IconButton>
            <IconButton
              icon={<FontAwesomeIcon icon="fas fa-cloud-arrow-down" />}
              href={LINKS.presskit}
            >
              Presskit
            </IconButton>
          </Stack>
          <Box sx={{ pt: 5 }} className="tos-anim2">
            <SocialLinks />
          </Box>
        </Box>
        <FloatingButton />
      </Stack>
      <video
        style={{
          zIndex: '-1',
          position: 'absolute',
          objectFit: 'cover',
          width: '100vw',
          height: '100vh',
          top: 0,
          left: 0,
          padding: 'none',
        }}
        autoPlay
        loop
        muted
        poster={background_img}
        src={background}
      />
    </Box>
  )
}

export default Homepage
