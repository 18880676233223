import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, Tooltip, Typography } from '@mui/material'

const AwesomeLink = (props) => {
  const size = '32px'
  return (
    <Tooltip
      title={
        <Typography sx={{ letterSpacing: '1px', textTransform: 'capitalize' }}>
          {props.name}
        </Typography>
      }
    >
      <Link
        href={props.href}
        target="blank"
        sx={{
          '& > * ': {
            color: 'white',
          },
          '&:hover': {
            '& > *': {
              color: 'lightblue',
            },
          },
        }}
      >
        <FontAwesomeIcon
          icon={props.icon}
          size="xl"
          style={{ height: size, width: size }}
        />
      </Link>
    </Tooltip>
  )
}

AwesomeLink.propTypes = {
  name: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
}

export default AwesomeLink
