export const LINKS = {
  steam: 'https://store.steampowered.com/app/1712690/SourceWorlds/',
  reddit: 'https://www.reddit.com/r/sourceworlds/',
  twitter: 'https://mobile.twitter.com/tideoverstudios',
  youtube: 'https://www.youtube.com/channel/UCUDPJpWl07e5hgp4d3tJIKA',
  instagram: 'https://www.instagram.com/tideoverstudios/',
  discord: 'https://discord.gg/qG7u3vkquf',
  presskit: '/files/TOS_Press-Kit.zip',
  trailer: 'https://www.youtube.com/watch?v=OMdTUsz2Ijk',
  tiktok: 'https://www.tiktok.com/@tideoverstudios',
}

export const PAGES = [
  { name: 'Home', path: '/home' },
  // {
  //   name: 'News',
  //   path: 'https://store.steampowered.com/news/app/1712690',
  // },
  // { name: 'Roadmap', path: '/roadmap' },
  // {
  //   name: 'Presskit',
  //   path: 'https://tideoverstudios.com/wp-content/uploads/2021/12/TOS_Press-Kit.zip',
  // },
  {
    name: 'SourceWorlds',
    items: [
      {
        name: 'The Game',
        path: 'https://store.steampowered.com/app/1712690/SourceWorlds/',
      },
      { name: 'Roadmap', path: '/roadmap' },
      { name: 'News', path: 'https://store.steampowered.com/news/app/1712690' },
    ],
  },
  { name: '{Tech}', path: 'https://www.tideover.de' },
  { name: 'About', path: '/about' },
  { name: 'Impressum', path: '/impressum' },
]

export const COLORS = {
  spa: 'rgb(166,185,173)',
  lightblue: 'rgb(173,216,230)',
}

export const SOCIALS = [
  {
    name: 'steam',
    href: LINKS.steam,
    icon: 'fab fa-steam',
  },
  {
    name: 'reddit',
    href: LINKS.reddit,
    icon: 'fab fa-reddit',
  },
  {
    name: 'twitter',
    href: LINKS.twitter,
    icon: 'fab fa-twitter',
  },
  {
    name: 'youtube',
    href: LINKS.youtube,
    icon: 'fab fa-youtube',
  },
  {
    name: 'instagram',
    href: LINKS.instagram,
    icon: 'fab fa-instagram',
  },
  {
    name: 'discord',
    href: LINKS.discord,
    icon: 'fab fa-discord',
  },
  { name: 'tiktok', href: LINKS.tiktok, icon: 'fab fa-tiktok' },
]
