import React from 'react'
import { Button, Avatar } from '@mui/material'
import cat_gif from '../assets/images/cat.gif'

const FloatingButton = (props) => {
  return (
    <Button
      sx={{
        position: 'absolute',
        bottom: '20px',
        right: '20px',
        boxShadow: '2px 2px 3px #999',
        borderRadius: '50%',
        p: 0,
        minWidth: '50px',
      }}
      onClick={() => {
        window.location.replace(window.location.origin + '/cat')
      }}
    >
      <Avatar alt="cat" src={cat_gif} sx={{ width: '50px', height: '50px' }} />
    </Button>
  )
}

export default FloatingButton
