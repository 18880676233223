import React from 'react'
import { Box, Card, Divider, Grid, ListItemIcon, Stack } from '@mui/material'

import BaseTypography from '@mui/material/Typography'

import {
  Speed,
  Stars,
  Translate,
  Brush,
  SportsEsports,
  SportsKabaddi,
  Map,
  Castle,
  Coronavirus,
  CurrencyExchange,
  ElderlyWoman,
  TravelExplore,
  BugReport,
  Feedback,
} from '@mui/icons-material'
import RoadmapBox from '../components/RoadmapBox'
import logo from '../assets/images/sourceworlds-logo.png'
import background from '../assets/images/CHURCH.jpg'

const Typography = (props) => {
  return (
    <BaseTypography
      {...props}
      sx={{ color: 'black', fontFamily: 'Gagalin, sans-serif', ...props.sx }}
    >
      {props.children}
    </BaseTypography>
  )
}

const released = [
  { text: 'better performance', icon: <Speed />, status: true },
  { text: 'new areas and new items', icon: <Castle />, status: true },
  { text: 'german translation', icon: <Translate />, status: true },
  { text: 'ui redesign', icon: <Brush />, status: true },
]

const shortTerm = [
  {
    text: 'new gamemode: PvP ',
    icon: <SportsKabaddi />,
    status: true,
  },
  {
    text: 'new map: the arena',
    icon: <Map />,
    status: true,
  },
  { text: 'gamepad support', icon: <SportsEsports />, status: true },
]

const midTerm = [
  { text: 'more side quests', icon: <Stars />, status: false },

  {
    text: 'more languages (ita, esp)',
    icon: <Translate />,
    status: false,
  },
  {
    text: 'procedurally generated endlessdungeon',
    icon: <Castle />,
    status: true,
  },
]

const longTerm = [
  { text: 'new maps', icon: <Map />, status: false },
  {
    text: 'new opponents',
    icon: <ElderlyWoman />,
    status: false,
  },
  {
    text: 'trading system',
    icon: <CurrencyExchange />,
    status: true,
  },
  {
    text: 'bossfights',
    icon: <Coronavirus />,
    status: false,
  },
  { text: 'new endgame', icon: <TravelExplore />, status: false },
]

const ongoingDev = [
  {
    text: 'user suggestions',
    icon: <Feedback />,
    description:
      'We want to develop the game with you. Send your suggestions to us!',
  },
  {
    text: 'performance optimizations',
    icon: <Speed />,
    description:
      'We want everybody to play our game. So we always try to optimize the performance.',
  },
  {
    text: 'bugfixes',
    icon: <BugReport />,
    description:
      'We want you to enjoy the game. So bug fixes will always be a priority to us.',
  },
]

const Roadmap = (props) => {
  return (
    <Box>
      <Stack
        sx={{
          p: 3,
          height: '85vh',
          overflowY: 'auto',
          px: {
            xl: 25,
          },
        }}
      >
        <Box sx={{ pb: 2 }}>
          <Stack
            direction="row"
            sx={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <Typography variant="h3">Roadmap</Typography>
            <img src={logo} alt="SourceWorlds Logo" height="50px" />
          </Stack>
          <Divider />
        </Box>
        <Grid container spacing={2} alignItems="stretch">
          <RoadmapBox
            title="RELEASED"
            titleColor="MediumSeaGreen"
            goals={released}
            md={3}
          />
          <RoadmapBox
            title="SHORT TERM"
            titleColor="cadetblue"
            goals={shortTerm}
            md={3}
          />
          <RoadmapBox
            title="MID TERM"
            titleColor="orange"
            goals={midTerm}
            md={3}
          />
          <RoadmapBox
            title="LONG TERM"
            titleColor="salmon"
            goals={longTerm}
            md={3}
          />
          <Grid item xs={12} className="tos-anim1">
            <Card sx={{ pb: 2, backgroundColor: 'rgba(0, 0, 0, 0.3)' }}>
              <Box sx={{ backgroundColor: 'lightgray', p: 1 }}>
                <Typography variant="h6">ONGOING DEVELOPMENT</Typography>
              </Box>
              <Grid container spacing={2} sx={{ p: 2 }}>
                {ongoingDev.map((ele, i) => {
                  return (
                    <Grid key={i} item xs={12} md={4} sx={{ display: 'grid' }}>
                      <Card
                        sx={{
                          width: '100%',
                          backgroundColor: 'rgba(255, 255, 255, 0.5)',
                        }}
                      >
                        <Stack spacing={1} sx={{ p: 2 }}>
                          <Stack
                            direction="row"
                            sx={{ display: 'flex', alignItems: 'center' }}
                          >
                            {ele.icon && (
                              <ListItemIcon>{ele.icon}</ListItemIcon>
                            )}
                            <Typography
                              variant="h6"
                              sx={{ textTransform: 'capitalize' }}
                            >
                              {ele.text}
                            </Typography>
                          </Stack>
                          <Typography>{ele.description}</Typography>
                        </Stack>
                      </Card>
                    </Grid>
                  )
                })}
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Stack>
      <img
        style={{
          zIndex: '-1',
          position: 'absolute',
          objectFit: 'cover',
          width: '100vw',
          height: '100vh',
          top: 0,
          left: 0,
          padding: 'none',
        }}
        src={background}
        alt=""
      />
    </Box>
  )
}

export default Roadmap
