import { Box, Menu, Typography } from '@mui/material'
import React from 'react'
import { getTextColor } from '../util/util'
import NavItem from './NavItem'

const NavMenu = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.target)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Box
        onClick={handleClick}
        sx={{
          color: getTextColor(props.bgColor),
          display: 'flex',
          alignItems: 'center',
          height: '64px',
          backgroundColor: 'rgb(255,255,255,0)',
          px: 2,
          '&:hover': {
            cursor: 'pointer',
            backgroundColor: props.bgColor,
            filter: 'brightness(60%)',
          },
        }}
      >
        <Typography sx={{ letterSpacing: '1px' }}>{props.name}</Typography>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{
          sx: {
            backgroundColor: `${props.bgColor} !important`,
          },
        }}
      >
        {props.items.map((item) => {
          return (
            <NavItem
              key={item.name}
              {...item}
              bgColor={props.bgColor}
              onClick={handleClose}
            />
          )
        })}
      </Menu>
    </>
  )
}

export default NavMenu
