import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './assets/rakesly.css';
import './assets/gagalin.css';
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import App from './App';

const theme = createTheme({
  typography: {
    fontFamily: 'Rakesly, Gagalin, Arial',
  },
});

theme.typography.h4 = {
  ...theme.typography.h4,
  fontSize: '2.125rem',

  [theme.breakpoints.up('xs')]: {
    fontSize: '1.2rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '2.125rem',
  },
};

theme.typography.h3 = {
  ...theme.typography.h3,
  fontSize: '3rem',
  [theme.breakpoints.up('xs')]: {
    fontSize: '1.8rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '3rem',
  },
};

theme.typography.h1 = {
  ...theme.typography.h1,
  fontSize: '6rem',
  [theme.breakpoints.up('xs')]: {
    fontSize: '3.8rem',
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: '6rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '9rem',
  },
};

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <CssBaseline>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </CssBaseline>
  </ThemeProvider>,
  document.getElementById('root')
);
