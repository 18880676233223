import { Box, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { useMatch, useResolvedPath, useNavigate } from 'react-router-dom'
import { getTextColor } from '../util/util'

const NavItem = (props) => {
  let navigate = useNavigate()
  let resolved = useResolvedPath(props.path)
  let match = useMatch({ path: resolved?.pathname, end: true })

  const handleClick = (event) => {
    if (props.path.startsWith('/')) {
      navigate(props.path)
    } else {
      window.open(props.path, '_blank')
    }
    if (props.onClick) {
      props.onClick(event)
    }
  }

  return (
    <Box
      onClick={handleClick}
      sx={{
        color: getTextColor(props.bgColor),
        display: 'flex',
        alignItems: 'center',
        height: '64px',
        backgroundColor: match ? props.bgColor : 'rgb(255,255,255,0)',
        filter: match ? 'brightness(65%)' : null,
        px: 2,
        '&:hover': {
          cursor: 'pointer',
          backgroundColor: props.bgColor,
          filter: 'brightness(60%)',
        },
      }}
    >
      <Typography sx={{ letterSpacing: '1px' }}>{props.name}</Typography>
    </Box>
  )
}

NavItem.propTypes = {
  path: PropTypes.string.isRequired,
  bgColor: PropTypes.string.isRequired,
  onClick: PropTypes.func,
}

export default NavItem
