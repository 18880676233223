import { Stack } from '@mui/material'
import PropTypes from 'prop-types'
import { SOCIALS } from '../util/constants'
import AwesomeLink from './AwesomeLink'

const SocialLinks = (props) => {
  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{
        overflow: 'wrap',
        justifyContent: 'center',
      }}
    >
      {SOCIALS.map((ele) => {
        return (
          <AwesomeLink
            key={ele.name}
            name={ele.name}
            icon={ele.icon}
            href={ele.href}
          />
        )
      })}
    </Stack>
  )
}

SocialLinks.propTypes = {
  steam: PropTypes.bool,
  reddit: PropTypes.bool,
  twitter: PropTypes.bool,
  youtube: PropTypes.bool,
  instagram: PropTypes.bool,
  discord: PropTypes.bool,
}

export default SocialLinks
