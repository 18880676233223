import { Grid, Stack, Toolbar } from '@mui/material';
import Appbar from './AppBar';

const Layout = (props) => {
  return (
    <>
      <Appbar />
      <Grid container>
        <Grid item xs={0} />
        <Grid item xs={12} sx={{ display: 'flex', flexGrow: 1 }}>
          <Stack sx={{ display: 'flex', flexGrow: 1 }}>
            <Toolbar sx={{ mt: { md: 4 } }} />
            {props.children}
          </Stack>
        </Grid>
        <Grid item xs={0} />
      </Grid>
    </>
  );
};

export default Layout;
