import { ListItemButton, ListItemText } from '@mui/material'
import PropTypes from 'prop-types'
import { useMatch, useResolvedPath, useNavigate } from 'react-router-dom'

const NavItemMobile = (props) => {
  const navigate = useNavigate()
  let resolved = useResolvedPath(props.path)
  let match = useMatch({ path: resolved?.pathname, end: true })
  let isSelected = Boolean(match)
  let bgColor = props.bgColor || 'rgb(173,216,230,1)'

  const handleClick = (event) => {
    if (props.path.startsWith('/')) {
      navigate(props.path)
    } else {
      window.open(props.path, '_blank')
    }
    if (props.onClick) {
      props.onClick(event)
    }
  }

  return (
    <ListItemButton
      selected={isSelected}
      onClick={handleClick}
      sx={{
        backgroundColor: isSelected ? `${bgColor} !important` : null,
        filter: isSelected ? 'brightness(65%) !important' : null,
        ...props.sx,
      }}
    >
      <ListItemText>{props.name}</ListItemText>
    </ListItemButton>
  )
}

NavItemMobile.propTypes = {
  name: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  bgColor: PropTypes.string,
}

export default NavItemMobile
