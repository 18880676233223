export const getTextColor = (bgColor) => {
  var r, g, b, color
  if (bgColor.charAt(0) === '#') {
    color = bgColor.substring(1, 7)
    r = parseInt(color.substring(0, 2), 16) // hexToR
    g = parseInt(color.substring(2, 4), 16) // hexToG
    b = parseInt(color.substring(4, 6), 16) // hexToB
  } else {
    color = bgColor.replace('rgb(', '').replace(')', '').split(',')
    r = color[0]
    g = color[1]
    b = color[2]
  }
  var uicolors = [r / 255, g / 255, b / 255]
  var c = uicolors.map((col) => {
    if (col <= 0.03928) {
      return col / 12.92
    }
    return Math.pow((col + 0.055) / 1.055, 2.4)
  })
  var L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2]
  return L > 0.179 ? 'black' : 'white'
}
