import { Box, Grid, Link, Paper, Stack } from '@mui/material'
import BaseTypography from '@mui/material/Typography'
import treehouse from '../assets/images/impressum.jpg'
import ImpressumBlock from '../components/ImpressumBlock'

const Typography = (props) => {
  return (
    <BaseTypography {...props} sx={{ fontFamily: 'Arial', ...props.sx }}>
      {props.children}
    </BaseTypography>
  )
}

const Impressum = (props) => {
  return (
    <Box>
      <Grid container>
        <Grid item xs={0} md={6} />
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            p: 2,
          }}
        >
          <Paper
            sx={{
              height: '85vh',
              p: 2,
              opacity: 0.9,
              overflowY: 'scroll',
            }}
          >
            <Stack>
              <Typography variant="h2">Impressum</Typography>
              <Typography>Angaben gemäß §5 TMG:</Typography>
              <Typography sx={{ fontWeight: 'bold', pt: 2 }}>
                TideOver Studios UG (haftungsbeschränkt)
              </Typography>
              <Typography>Hohenzollernring 47</Typography>
              <Typography>72160 Horb am Neckar</Typography>
              <Typography>Vertreten durch:</Typography>
              <Typography>
                Florian März, Sebastian Pfitzer, Kevin Kellenberger, Marco
                Breunig
              </Typography>
              <Typography sx={{ pt: 2, fontWeight: 'bold' }}>
                Kontakt
              </Typography>

              <Typography>
                E-Mail:{' '}
                <Link href="mailto:info@tideoverstudios.de">
                  info@tideoverstudios.de
                </Link>
              </Typography>

              <Typography>
                Telefon: <Link href="tel:074515209961">07451/5209961</Link>
              </Typography>
              <Typography sx={{ pt: 2, fontWeight: 'bold' }}>
                Eintragung im Handelsregister:
              </Typography>
              <Typography>
                Registergericht: Amtsgericht Stuttgart HRB 779244
              </Typography>
              <Typography>
                Umsatzsteuer-Identifikationsnummer gemäß §27 a
                Umsatzsteuergesetz: DE343061827
              </Typography>
              <Typography sx={{ pt: 2 }}>
                Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV
              </Typography>
              <Typography>Florian März, Marco Breunig</Typography>

              <ImpressumBlock
                title="OS-Platform"
                text="Die EU-Kommission stellt eine Internetplattform zur Online-Beilegung von Streitigkeiten (sog. „OS-Plattform“) bereit. Die OS-Plattform soll als Anlaufstelle zur außergerichtlichen Beilegung von Streitigkeiten betreffend vertragliche Verpflichtungen, die aus Online-Kaufverträgen erwachsen, dienen. Die OS-Plattform ist unter folgendem Link erreichbar: http://ec.europa.eu/consumers/odr/. Wir sind nicht bereit an einer solchen außergerichtlichen Streitbeilegung zwischen uns und unseren Kunden teilzunehmen."
              />
              <ImpressumBlock
                title="Haftungsausschluss (Disclaimer) Haftung für Inhalte"
                text="Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen."
              />
              <ImpressumBlock
                title="Haftung für Links"
                text="Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen."
              />
              <ImpressumBlock
                title="Urheberrecht"
                text="Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen."
              />
            </Stack>
          </Paper>
        </Grid>
      </Grid>
      <img
        style={{
          zIndex: '-1',
          position: 'absolute',
          objectFit: 'cover',
          width: '100vw',
          height: '100vh',
          top: 0,
          left: 0,
          padding: 'none',
        }}
        src={treehouse}
        alt=""
      />
    </Box>
  )
}

export default Impressum
