import { Box, Link, Stack, Typography } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types'
import { COLORS } from '../util/constants'

const IconButton = (props) => {
  return (
    <Link href={props.href} target="blank" underline="none">
      <Stack
        direction="row"
        sx={{
          display: 'flex',
          alignItems: 'center',
          letterSpacing: '1px',
          color: 'black',
          backgroundColor: COLORS.lightblue,
          '&:hover': {
            filter: 'brightness(80%)',
            cursor: 'pointer',
          },
          borderRadius: '4px',
          px: '15px',
          py: '5px',
          // width: { xs: '50%', md: '100%' },
        }}
      >
        <Typography>{props.children}</Typography>
        <Box sx={{ marginLeft: '8px' }}>{props.icon}</Box>
      </Stack>
    </Link>
  )
}

IconButton.propTypes = {
  icon: PropTypes.object,
  href: PropTypes.string,
}

export default IconButton
